$fonts-url-path: "app/styleguide/styles/fonts/files";

$opensans-light-path: "#{$fonts-url-path}/OpenSans-Light";
$opensans-regular-path: "#{$fonts-url-path}/OpenSans-Regular";
$opensans-semibold-path: "#{$fonts-url-path}/OpenSans-Semibold";
$opensans-bold-path: "#{$fonts-url-path}/OpenSans-Bold";

$poppins-light-path: "#{$fonts-url-path}/Poppins-300";
$poppins-regular-path: "#{$fonts-url-path}/Poppins-regular"; // 400
$poppins-medium-path: "#{$fonts-url-path}/Poppins-500";
$poppins-semibold-path: "#{$fonts-url-path}/Poppins-600";
$poppins-bold-path: "#{$fonts-url-path}/Poppins-700";
$poppins-extrabold-path: "#{$fonts-url-path}/Poppins-800";

@font-face {
  font-family: OpenSans;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("#{$opensans-light-path}.woff2") format("woff2"), url("#{$opensans-light-path}.woff") format("woff");
}

@font-face {
  font-family: OpenSans;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("#{$opensans-regular-path}.woff2") format("woff2"), url("#{$opensans-regular-path}.woff") format("woff");
}

@font-face {
  font-family: OpenSans;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("#{$opensans-semibold-path}.woff2") format("woff2"), url("#{$opensans-semibold-path}.woff") format("woff");
}

@font-face {
  font-family: OpenSans;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("#{$opensans-bold-path}.woff2") format("woff2"), url("#{$opensans-bold-path}.woff") format("woff");
}

@font-face {
  font-family: Poppins;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("#{$poppins-light-path}.woff2") format("woff2"), url("#{$poppins-light-path}.woff") format("woff");
}

@font-face {
  font-family: Poppins;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("#{$poppins-regular-path}.woff2") format("woff2"), url("#{$poppins-regular-path}.woff") format("woff");
}

@font-face {
  font-family: Poppins;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("#{$poppins-medium-path}.woff2") format("woff2"), url("#{$poppins-medium-path}.woff") format("woff");
}

@font-face {
  font-family: Poppins;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("#{$poppins-semibold-path}.woff2") format("woff2"), url("#{$poppins-semibold-path}.woff") format("woff");
}

@font-face {
  font-family: Poppins;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("#{$poppins-bold-path}.woff2") format("woff2"), url("#{$poppins-bold-path}.woff") format("woff");
}

@font-face {
  font-family: Poppins;
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url("#{$poppins-extrabold-path}.woff2") format("woff2"), url("#{$poppins-extrabold-path}.woff") format("woff");
}
