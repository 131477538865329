/* 
This file should only contain colors from Color Palette
*/

@import "~app/styleguide/styles/colors/palette";

// base Uicolor
$white: $color-shades-0;
$black: $color-shades-100;

$color-general: $color-neutral-800;

$color-primary-lightest: $color-primary-50;
$color-primary-lighter: $color-primary-100;
$color-primary: $color-primary-500;
$color-primary-hover: $color-primary-600;
$color-primary-active: $color-primary-700;

$color-neutral-lightest: $color-neutral-50;
$color-neutral-lighter: $color-neutral-100;
$color-neutral-light: $color-neutral-400;
$color-neutral: $color-neutral-500;
$color-neutral-dark: $color-neutral-600;
$color-neutral-darker: $color-neutral-700;
$color-neutral-darkest: $color-neutral-900;

$color-error: $color-error-500;
$color-attention: $color-error-500;
$color-warning: $color-warning-300;

// verification
$color-verification-success: $color-success-500;
$color-verification-partial: $color-warning-300;
$color-verification-progress: $color-secondary-500;
$color-verification-error: $color-error;

// socials
$color-airbnb-pink: #f95f62;
$color-bookingcom-blue: #0c3b7c;
$color-vrbo-blue: #245abc;

/*
OLD colors start here
*/
// Base Uicolor
$orange: #f5764f;

$vrbo-blue-light: #eff5f7;

$bookingcom-calendar: #03cccb;
$beige-lighter: #fff8f3;
$beige-light: #fff8eb;
$beige: #f9ebd3;
$beige-dark: #fef8ed;

$green-very-dark: #5eaa66;
$green-darker: #00a72d;
$green-darkest: #235c47; // web vitals - accessibility
$green: #2dd886;
$green-light: #6ee7b7;
$green-lightest: #f2fff4;
$hichee-calendar: #576382;
$teal: #009897;
$teal-lighter: #c7dfdf;
$teal-lightest: #ebf9f9;
$pink: #fff4f2;

$airbnb-pink-light: #fff8eb;
$airbnb-pink-dark: #f95f62;
$red-light: #feefef;
$red: #ff000f;
$danger: #ef4444;
$red-dark: #cd3301;
$red-darker: #e23636;
$grayish-blue: #aabbc2;
$light-sea-blue: #7eaab7;
$light-pink: #fffafa;

$anti-flash-white: #eff5f7;

$lighter-gray: #7e8388;

$gray-darker: #ddd;
$gray-light: #777;
$gray-lighter: #b8b8b8;
$gray-more-lighter: #aaa;
$gray-medium-shade: #7e8388;
$gray-lightest: #e8e8e8;
$gray-extra-light: #f5f5f5;
$gray-extra-light-shade: #e3e3e3;
$gray-lighter-shade: #d6dfe3;
$gray-light-shade: #e3eaec;
$gray-lightest-shade: #b8c7ce;
$gray-very-light: #eff2f4;
$gray-regular-light: #f3f4f4;
$darker-gray-shadow: rgba(0, 0, 0, 0.1);
$gray-shadow: rgba(0, 0, 0, 0.25);
$dark-gray-shadow: rgba(0, 0, 0, 0.5);
$card-shadow: rgba(43, 72, 101, 0.24);
$silver-darker: #a6b0b8;
$silver-dark: #7f92a9;
$silver: #eff5f7;
$silver-light: #ebebeb;

$white-shadow: rgba(255, 255, 255, 0.25);
$white-shadow-darker: rgba(255, 255, 255, 0.17);
$white-shadow-lighter: rgba(255, 255, 255, 0.5);

$text-blue-lighter-shade: #8597a8;
$text-blue-extra-light: #8d9dad;
$text-black-light: #939596;
$blue-background-light: #f1f5f7;
$blue-background: #ebf9f9;
$gray-transparent-background: rgba(42, 43, 43, 0.5);
$yellow: #ffbd42;
$yellow-light: #f8e53e;
$yellow-dark: #fcd34d;
$bright-yellow: #ff0;
$mustard-yellow: #ffcd00;
$blue-lighter-shade: #a1b1b9;
