/* stylelint-disable declaration-colon-space-after */

@import "../colors/index";

// Only bootstrap variables should stay here
$gray-base: #000 !default;

$brand-primary: $color-primary;
$brand-primary-light: $color-primary-50;
$brand-success: $color-success-500;
$brand-info: $color-success-500;
$brand-warning: $color-warning-500;
$brand-danger: $color-error;
$brand-general: $color-neutral;
$brand-light-extra: $color-neutral-50;

// Message
$message-alert: $beige;
$message-info: $color-neutral-lighter;

// Common
$body-bg: $white;
$text-color: $color-general;

$link-black: #000;
$link-primary: $color-primary;
$link-general: $color-primary;
$link-color: $color-primary;
$link-hover-color: $color-primary-hover;
$link-hover-decoration: underline;

$border-radius-base: 0;
$border-radius-large: 0;
$border-radius-small: 0;

// Fonts
$font-size-base: 14px;
$font-weight-base: 400;
$font-family-sans-serif: "OpenSans";
$font-family-base: "Poppins", $font-family-sans-serif, sans-serif;

// Form elements
$form-icon-selected-color: $brand-general;
$form-icon-hover-color: lighten($form-icon-selected-color, 7%);
$input-bg-disabled: $gray-lightest;
$input-group-addon-bg: $gray-lightest;

// Buttons
$btn-border-radius-base: 0;
$btn-border-radius-large: 0;
$btn-border-radius-small: 0;

// Media queries breakpoints

// NOTE: do not use the media queries variables below. Use the variables/mixins on
// client/app/styleguide/styles/misc/responsive.scss

$screen-sm: 640px;
$screen-md: 768px;
$screen-lg: 1024px;

$screen-sm-min: $screen-sm;
$screen-xs-max: $screen-sm-min - 1;

// Grid
$grid-gutter-width: 30px;
$container-large-desktop: 1024px;

// Navbar
// NB: Keep in sync with `../resources/variables.js`
$navbar-height: 72px;
$navbar-margin-bottom: 0;
$navbar-default-bg: #fff;
$navbar-default-color: $text-color;
$navbar-default-border: 0;
$navbar-default-link-color: $text-color;

// Inverted navbar
$navbar-inverse-bg: #131313;

// Navs
$nav-link-padding-v: 15px;
$nav-link-padding-h: 20px;
$nav-link-padding: $nav-link-padding-v $nav-link-padding-h;
$nav-link-hover-bg: $color-general;

// Form states and alerts
// NOTE: Keep in sync w/ `styles/resources/colors.js`
$state-success-text: #3c763d;
$state-success-bg: #dff0d8;
$state-success-border: darken($state-success-bg, 5%);

$state-info-text: #31708f;
$state-info-bg: #d9edf7;
$state-info-border: darken($state-info-bg, 7%);

$state-warning-text: #8a6d3b;
$state-warning-bg: #fcf8e3;
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%);

$state-danger-text: #a94442;
$state-danger-bg: #f2dede;
$state-danger-border: darken($state-danger-bg, 5%);

// Modals
$modal-title-padding: 15px;
$modal-inner-padding: 24px;
$modal-inner-padding-mobile: 24px;
$modal-side-panel-padding: 80px;

// Tabs
$nav-tabs-link-hover-border-color: $gray-lightest;

// Pagination
$pagination-hover-bg: #fff;

// Jumbotron
$jumbotron-bg: $gray-lightest;

// List groups
$list-group-disabled-bg: $gray-lightest;

// Panels
$panel-heading-padding-v: 10px;
$panel-heading-padding-h: 15px;
$panel-heading-padding: $panel-heading-padding-v $panel-heading-padding-h;

// Type
$blockquote-border-color: $gray-lightest;
$page-header-border-color: $gray-lightest;
$hr-border: $gray-lightest;

$grid-float-breakpoint: $screen-md;
