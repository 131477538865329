@import "~app/styleguide/styles/misc/variables";
@import "~app/styleguide/styles/bootstrap/pre-customizations";

* {
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
}

label {
  cursor: pointer;
  font-weight: $font-weight-base;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 0;
}

select {
  option[default][disabled] {
    color: $text-disabled;
  }

  option {
    color: #000;
  }
}

figure {
  margin: 0;
}
