:global {
  .listings-import {
    @include main-container();

    padding-bottom: 30px;

    table,
    th,
    td {
      border: 1px solid #808080; // grey;
    }

    th {
      text-align: center;
      padding: 5px;
    }

    td {
      padding: 5px;
    }
  }

  .streamline-unit-id-select {
    option:disabled {
      color: lighten($gray-light, 20%);
    }
  }
}
