$color-primary: #2a9990;
$color-gray: #cdcdcd;
$color-placeholder-text: #888;

:global {
  .auth-form {
    margin-bottom: 10px;
    border: 1px solid $color-gray;
    border-radius: 4px;
    background: #fff;
    padding: 35px 0;

    @include mobile-legacy {
      border: 0;
    }

    input[type="checkbox"] {
      margin-top: 4px; // little fix
    }

    .btn-submit {
      transition: background-color 0.3s;
      border: 1px solid $color-primary;
      border-radius: 3px;
      background-color: $color-primary;
      padding: 10px;
      color: #fff;

      @include mobile-legacy {
        margin-bottom: 15px;
      }

      &:hover,
      /* while pressing*/
      &:active,
      /* after pressing and the user doesn't click anywhere*/
      &:focus {
        border: 1px solid $color-primary;
        background-color: darken($color-primary, 5%);
        color: #fff;
      }
    }
  }

  .another-auth {
    position: absolute;
    top: 10px;
    left: 47%;
    background-color: #fff;
    width: 24px;
    height: 24px;
    text-align: center;
  }

  .devise {
    padding-top: 100px;

    > h2 {
      margin-bottom: 25px;
      text-align: center;
    }

    hr {
      border-top-color: $color-gray;
    }
  }

  .devise-title {
    margin-bottom: 25px;
  }

  .devise-subtitle {
    margin-bottom: 30px;
    text-align: center;
    font-size: 19px;

    @include mobile-legacy {
      font-size: 15px;
    }
  }

  .form-left-bottom-content {
    padding-left: 0;

    @include mobile-legacy {
      padding-left: inherit;
    }
  }

  .btn-submit {
    transition: background-color 0.3s;
    border: 1px solid $color-primary;
    border-radius: 3px;
    background-color: $color-primary;
    padding: 10px 30px;
    color: #fff;

    &:hover,
    &:active,
    &:focus,
    &:link {
      border: 1px solid $color-primary;
      background-color: darken($color-primary, 5%);
      color: #fff;
    }
  }
}
